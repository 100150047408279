<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="form.id"
			ref="general"
			name-label="Stripe content block name*"
			:name.sync="form.name"
			:priority.sync="form.priority"
			:status.sync="form.status"
			:widgets.sync="form.widgets"
			:widget-type="widgetType"
			show-widget
		/>

		<BannerDate
			ref="date"
			:status.sync="form.status"
			:start-date.sync="form.startDate"
			:start-time.sync="form.startTime"
			:end-date.sync="form.endDate"
			:end-time.sync="form.endTime"
		/>

		<!-- TODO S7APP-86 Change desktop-img and mobile-img -->
		<BannerContentWrapper
			ref="content"
			required-link
			:banner-link.sync="form.link"
			has-badge-label
			:badge-labels.sync="form.badgeLabels"
		>
			<template #title>
				Strip content block
			</template>

			<div class="mt-4">
				<label>Description TH</label>
				<BaseRichTextEditor
					v-model="form.descriptionTh"
					:is-valid="!$v.descriptionTh.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. Compare iPhone >>"
				/>
			</div>

			<div>
				<label>Description EN</label>
				<BaseRichTextEditor
					v-model="form.descriptionEn"
					:is-valid="!$v.descriptionEn.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. Compare iPhone >>"
				/>
			</div>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? $t('global.general.delete.remove', { type: 'content block' }) : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: ROUTE_NAME.CONTENT_BLOCK_STRIPE_LIST })"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteContentBlock.bind(null, form.id)"
			:title="$t('global.general.delete.title', { type: 'content block' })"
			:description="$t('global.general.delete.descriptionWithLinked', { type: 'content block' })"
			@onSuccess="$router.push({ name: ROUTE_NAME.CONTENT_BLOCK_STRIPE_LIST })"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';

import { WIDGET_TYPE } from '../enums/widgets';
import { CONTENT_BLOCK_TYPE_API } from '../enums/contentBlocks';
import { convertDateTimeToUTC } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'ContentBlockStripeForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
	},

	validations() {
		return {
			descriptionTh: {
				required,
			},
			descriptionEn: {
				required,
			},
		};
	},

	data() {
		return {
			form: {
				id: null,
				name: '',
				priority: 0,
				widgets: [],
				status: true,
				startDate: null,
				startTime: '00:00',
				endDate: null,
				endTime: '23:59',
				descriptionTh: '',
				descriptionEn: '',
				link: null,
				badgeLabels: [],
			},

			ROUTE_NAME,
		};
	},

	computed: {
		widgetType() {
			return WIDGET_TYPE.stripeContentBlock;
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.form.id = value.id;
					this.form.name = value.name;
					this.form.priority = value.priority;
					this.form.status = value.status;
					this.form.startDate = value.startDate;
					this.form.startTime = value.startTime;
					this.form.endDate = value.endDate;
					this.form.endTime = value.endTime;

					this.form.descriptionEn = value.descriptionEn;
					this.form.descriptionTh = value.descriptionTh;

					this.form.link = value.link;

					this.form.widgets = value.widgets;
					this.form.badgeLabels = value.labels;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteContentBlock: 'contentBlocks/deleteContentBlock',
		}),

		handleChangeEndDate(value) {
			this.form.endDate = value;
		},

		async handleSubmit() {
			const refs = this.$refs;
			const generalRef = refs.general.$v;
			const dateRef = refs.date.$v;
			const contentRef = refs.content.$v;

			generalRef.$touch();
			dateRef.$touch();
			contentRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentRef.$invalid
			) {
				const form = this.form;
				const data = {
					name: form.name,
					type: CONTENT_BLOCK_TYPE_API.STRIPE,
					priority: Number(form.priority, 10),
					is_visible: form.status,
					start_at: convertDateTimeToUTC(form.startDate, form.startTime),
					end_at: convertDateTimeToUTC(form.endDate, form.endTime),
					widget_ids: form.widgets.map((widget) => widget.id),
					description_th: form.descriptionTh,
					description_en: form.descriptionEn,
					link: form.link,
					label_ids: form.badgeLabels.map((item) => item.id) || [],
				};
				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>
