<template>
	<component
		:is="getContentBlockType.component"
		:is-loading="isLoading"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ContentBlockHighlightForm from '@/components/ContentBlockHighlightForm.vue';
import ContentBlockStripeForm from '@/components/ContentBlockStripeForm.vue';
import ContentBlockTextWithImageForm from '@/components/ContentBlockTextWithImageForm.vue';

import { CONTENT_BLOCK_TYPE, CONTENT_BLOCK_FORM_COMPONENTS } from '../enums/contentBlocks';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'ContentBlockCreate',

	components: {
		ContentBlockHighlightForm,
		ContentBlockStripeForm,
		ContentBlockTextWithImageForm,
	},

	props: {
		contentBlockType: {
			type: String,
			default: CONTENT_BLOCK_TYPE.HIGHLIGHT,
		},
	},

	computed: {
		...mapState('contentBlocks', {
			create: 'create',
		}),

		getContentBlockType() {
			return CONTENT_BLOCK_FORM_COMPONENTS[this.contentBlockType];
		},
		isLoading() {
			return this.create.isLoading;
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			createContentBlock: 'contentBlocks/createContentBlock',
		}),

		async handleSubmit(data) {
			try {
				await this.createContentBlock(data);

				this.$router.push({ name: this.getContentBlockType.routerTo });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
