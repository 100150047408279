<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="form.id"
			ref="general"
			name-label="Highlight content block name*"
			:name.sync="form.name"
			:priority.sync="form.priority"
			:status.sync="form.status"
			:widgets.sync="form.widgets"
			:widget-type="widgetType"
			show-widget
		/>

		<BannerDate
			ref="date"
			:status.sync="form.status"
			:start-date.sync="form.startDate"
			:start-time.sync="form.startTime"
			:end-date.sync="form.endDate"
			:end-time.sync="form.endTime"
		/>

		<BannerContentWrapper
			ref="content"
			has-title
			has-description
			has-link-text
			has-button-text
			required-title
			is-required-description
			is-required-button-text
			required-link
			:title-th.sync="form.titleTh"
			:title-en.sync="form.titleEn"
			:description-th.sync="form.descriptionTh"
			:description-en.sync="form.descriptionEn"
			:button-text-th.sync="form.linkTextTh"
			:button-text-en.sync="form.linkTextEn"
			:banner-link.sync="form.link"
		>
			<template #title>
				Highlight
			</template>
			<BaseMediaSelectorBox
				ref="image"
				v-model="form.file"
				title="Image"
				no-image-text="No content block"
				show-helper
				width="320"
				height="320"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? $t('global.general.delete.remove', { type: 'content block' }) : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_LIST })"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteContentBlock.bind(null, form.id)"
			:title="$t('global.general.delete.title', { type: 'content block' })"
			:description="$t('global.general.delete.descriptionWithLinked', { type: 'content block' })"
			@onSuccess="$router.push({ name: ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_LIST })"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';

import {
	CONTENT_BLOCK_IMAGE_TYPE,
	CONTENT_BLOCK_TYPE_API,
} from '../enums/contentBlocks';
import { WIDGET_TYPE } from '../enums/widgets';
import { convertDateTimeToUTC } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'ContentBlockHighlightForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
		BaseMediaSelectorBox,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			form: {
				id: null,
				name: '',
				priority: 0,
				startDate: null,
				startTime: '00:00',
				endDate: null,
				endTime: '23:59',
				widgets: [],
				status: true,
				titleTh: '',
				titleEn: '',
				descriptionTh: '',
				descriptionEn: '',
				linkTextTh: '',
				linkTextEn: '',
				link: '',
				file: null,
			},

			ROUTE_NAME,
		};
	},

	computed: {
		widgetType() {
			return WIDGET_TYPE.highlightContentBlock;
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.form.id = value.id;
					this.form.name = value.name;
					this.form.priority = value.priority;
					this.form.status = value.status;
					this.form.startDate = value.startDate;
					this.form.startTime = value.startTime;
					this.form.endDate = value.endDate;
					this.form.endTime = value.endTime;
					this.form.widgets = value.widgets;
					this.form.titleEn = value.titleEn;
					this.form.titleTh = value.titleTh;
					this.form.descriptionEn = value.descriptionEn;
					this.form.descriptionTh = value.descriptionTh;
					this.form.linkTextEn = value.linkTextEn;
					this.form.linkTextTh = value.linkTextTh;
					this.form.link = value.link;
					this.form.file = value.file;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteContentBlock: 'contentBlocks/deleteContentBlock',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;

			const contentRef = this.$refs.content.$v;
			const imageRef = this.$refs.image.$v;

			generalRef.$touch();
			dateRef.$touch();
			contentRef.$touch();
			imageRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentRef.$invalid &&
				!imageRef.$invalid
			) {
				const form = this.form;
				const data = {
					name: form.name,
					type: CONTENT_BLOCK_TYPE_API.HIGHLIGHT,
					priority: Number(form.priority, 10),
					is_visible: form.status,
					start_at: convertDateTimeToUTC(form.startDate, this.startTime),
					end_at: convertDateTimeToUTC(form.endDate, this.endTime),
					widget_ids: form.widgets.map((widget) => widget.id),
					title_th: form.titleTh,
					title_en: form.titleEn,
					description_th: form.descriptionTh,
					description_en: form.descriptionEn,
					link_text_th: form.linkTextTh,
					link_text_en: form.linkTextEn,
					link: form.link,
					file: form.file?.id,
					file_type: CONTENT_BLOCK_IMAGE_TYPE.HIGHLIGHT,
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>
